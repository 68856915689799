import React from 'react';
import ComponentCreator from '@docusaurus/ComponentCreator';

export default [
  {
    path: '/blog',
    component: ComponentCreator('/blog', 'bcb'),
    exact: true
  },
  {
    path: '/blog/archive',
    component: ComponentCreator('/blog/archive', '65a'),
    exact: true
  },
  {
    path: '/blog/page/2',
    component: ComponentCreator('/blog/page/2', '1af'),
    exact: true
  },
  {
    path: '/blog/release-0126',
    component: ComponentCreator('/blog/release-0126', 'f9b'),
    exact: true
  },
  {
    path: '/blog/release-0202',
    component: ComponentCreator('/blog/release-0202', '022'),
    exact: true
  },
  {
    path: '/blog/release-0222',
    component: ComponentCreator('/blog/release-0222', '9cc'),
    exact: true
  },
  {
    path: '/blog/release-0301',
    component: ComponentCreator('/blog/release-0301', 'e1c'),
    exact: true
  },
  {
    path: '/blog/release-0308',
    component: ComponentCreator('/blog/release-0308', 'c3e'),
    exact: true
  },
  {
    path: '/blog/release-0315',
    component: ComponentCreator('/blog/release-0315', '26a'),
    exact: true
  },
  {
    path: '/blog/release-0403',
    component: ComponentCreator('/blog/release-0403', '52c'),
    exact: true
  },
  {
    path: '/blog/release-0419',
    component: ComponentCreator('/blog/release-0419', '3f5'),
    exact: true
  },
  {
    path: '/blog/release-0510',
    component: ComponentCreator('/blog/release-0510', '0d5'),
    exact: true
  },
  {
    path: '/blog/release-0516',
    component: ComponentCreator('/blog/release-0516', '597'),
    exact: true
  },
  {
    path: '/blog/release-0628',
    component: ComponentCreator('/blog/release-0628', '188'),
    exact: true
  },
  {
    path: '/blog/release-0705',
    component: ComponentCreator('/blog/release-0705', '123'),
    exact: true
  },
  {
    path: '/blog/release-0719',
    component: ComponentCreator('/blog/release-0719', 'f27'),
    exact: true
  },
  {
    path: '/blog/tags',
    component: ComponentCreator('/blog/tags', '4c4'),
    exact: true
  },
  {
    path: '/blog/tags/ai-improve',
    component: ComponentCreator('/blog/tags/ai-improve', 'd89'),
    exact: true
  },
  {
    path: '/blog/tags/callback',
    component: ComponentCreator('/blog/tags/callback', '5d1'),
    exact: true
  },
  {
    path: '/blog/tags/claude',
    component: ComponentCreator('/blog/tags/claude', '373'),
    exact: true
  },
  {
    path: '/blog/tags/dall-e',
    component: ComponentCreator('/blog/tags/dall-e', '433'),
    exact: true
  },
  {
    path: '/blog/tags/desktop',
    component: ComponentCreator('/blog/tags/desktop', '2fa'),
    exact: true
  },
  {
    path: '/blog/tags/discord',
    component: ComponentCreator('/blog/tags/discord', '2c2'),
    exact: true
  },
  {
    path: '/blog/tags/docs',
    component: ComponentCreator('/blog/tags/docs', '6ed'),
    exact: true
  },
  {
    path: '/blog/tags/draw',
    component: ComponentCreator('/blog/tags/draw', '8be'),
    exact: true
  },
  {
    path: '/blog/tags/duplicate-bot',
    component: ComponentCreator('/blog/tags/duplicate-bot', '330'),
    exact: true
  },
  {
    path: '/blog/tags/enterprise',
    component: ComponentCreator('/blog/tags/enterprise', '892'),
    exact: true
  },
  {
    path: '/blog/tags/extension',
    component: ComponentCreator('/blog/tags/extension', '395'),
    exact: true
  },
  {
    path: '/blog/tags/function',
    component: ComponentCreator('/blog/tags/function', '439'),
    exact: true
  },
  {
    path: '/blog/tags/function-call',
    component: ComponentCreator('/blog/tags/function-call', '3df'),
    exact: true
  },
  {
    path: '/blog/tags/gpt-4-0125-preview',
    component: ComponentCreator('/blog/tags/gpt-4-0125-preview', '52c'),
    exact: true
  },
  {
    path: '/blog/tags/gpt-4-o',
    component: ComponentCreator('/blog/tags/gpt-4-o', 'afd'),
    exact: true
  },
  {
    path: '/blog/tags/http',
    component: ComponentCreator('/blog/tags/http', 'e81'),
    exact: true
  },
  {
    path: '/blog/tags/idea',
    component: ComponentCreator('/blog/tags/idea', 'a98'),
    exact: true
  },
  {
    path: '/blog/tags/llm',
    component: ComponentCreator('/blog/tags/llm', '5a7'),
    exact: true
  },
  {
    path: '/blog/tags/package',
    component: ComponentCreator('/blog/tags/package', 'c28'),
    exact: true
  },
  {
    path: '/blog/tags/plugin',
    component: ComponentCreator('/blog/tags/plugin', '362'),
    exact: true
  },
  {
    path: '/blog/tags/run-code',
    component: ComponentCreator('/blog/tags/run-code', 'f4c'),
    exact: true
  },
  {
    path: '/blog/tags/run-sety-by-step',
    component: ComponentCreator('/blog/tags/run-sety-by-step', 'ff7'),
    exact: true
  },
  {
    path: '/blog/tags/skip-steps',
    component: ComponentCreator('/blog/tags/skip-steps', 'f0c'),
    exact: true
  },
  {
    path: '/blog/tags/slash-command',
    component: ComponentCreator('/blog/tags/slash-command', 'ec2'),
    exact: true
  },
  {
    path: '/blog/tags/text-2-image',
    component: ComponentCreator('/blog/tags/text-2-image', '32b'),
    exact: true
  },
  {
    path: '/blog/tags/web-search',
    component: ComponentCreator('/blog/tags/web-search', 'b4c'),
    exact: true
  },
  {
    path: '/blog/tags/webhook',
    component: ComponentCreator('/blog/tags/webhook', '3e9'),
    exact: true
  },
  {
    path: '/blog/tags/widget',
    component: ComponentCreator('/blog/tags/widget', '650'),
    exact: true
  },
  {
    path: '/blog/tags/workfloww',
    component: ComponentCreator('/blog/tags/workfloww', '40e'),
    exact: true
  },
  {
    path: '/invite/',
    component: ComponentCreator('/invite/', '824'),
    exact: true
  },
  {
    path: '/invite/expired',
    component: ComponentCreator('/invite/expired', '24b'),
    exact: true
  },
  {
    path: '/license/',
    component: ComponentCreator('/license/', '707'),
    exact: true
  },
  {
    path: '/login/',
    component: ComponentCreator('/login/', 'e29'),
    exact: true
  },
  {
    path: '/login/loginSuccess',
    component: ComponentCreator('/login/loginSuccess', 'a40'),
    exact: true
  },
  {
    path: '/login/sso',
    component: ComponentCreator('/login/sso', '646'),
    exact: true
  },
  {
    path: '/main/',
    component: ComponentCreator('/main/', 'b49'),
    exact: true
  },
  {
    path: '/privacy-notice/',
    component: ComponentCreator('/privacy-notice/', '81c'),
    exact: true
  },
  {
    path: '/privacy-notice/privacy-notice',
    component: ComponentCreator('/privacy-notice/privacy-notice', 'fbd'),
    exact: true
  },
  {
    path: '/smart-card/',
    component: ComponentCreator('/smart-card/', '872'),
    exact: true
  },
  {
    path: '/smart-card/components/DisplayCard',
    component: ComponentCreator('/smart-card/components/DisplayCard', 'f33'),
    exact: true
  },
  {
    path: '/smart-card/components/InputCard',
    component: ComponentCreator('/smart-card/components/InputCard', '30b'),
    exact: true
  },
  {
    path: '/docs',
    component: ComponentCreator('/docs', '5b5'),
    routes: [
      {
        path: '/docs',
        component: ComponentCreator('/docs', '3c4'),
        routes: [
          {
            path: '/docs',
            component: ComponentCreator('/docs', 'd7e'),
            routes: [
              {
                path: '/docs/advanced/deployment',
                component: ComponentCreator('/docs/advanced/deployment', '5f5'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/advanced/sso',
                component: ComponentCreator('/docs/advanced/sso', '58c'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/advanced',
                component: ComponentCreator('/docs/category/advanced', '7b4'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/how-to-build-a-chatbot',
                component: ComponentCreator('/docs/category/how-to-build-a-chatbot', '425'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/how-to-build-a-flowbot',
                component: ComponentCreator('/docs/category/how-to-build-a-flowbot', 'aa7'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/category/introduction',
                component: ComponentCreator('/docs/category/introduction', 'd40'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/chatbot/',
                component: ComponentCreator('/docs/chatbot/', '179'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/chatbot/build',
                component: ComponentCreator('/docs/chatbot/build', 'f3c'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/flowbot/',
                component: ComponentCreator('/docs/flowbot/', '918'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/flowbot/build',
                component: ComponentCreator('/docs/flowbot/build', 'cb2'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/introduction/changelog',
                component: ComponentCreator('/docs/introduction/changelog', '868'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/introduction/quickstart',
                component: ComponentCreator('/docs/introduction/quickstart', 'f87'),
                exact: true,
                sidebar: "tutorialSidebar"
              },
              {
                path: '/docs/introduction/welcome',
                component: ComponentCreator('/docs/introduction/welcome', '30f'),
                exact: true,
                sidebar: "tutorialSidebar"
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/',
    component: ComponentCreator('/', 'cd1'),
    exact: true
  },
  {
    path: '*',
    component: ComponentCreator('*'),
  },
];
